"use client";
import Footer from "./footer";
import "./styles.scss";
// import posthog from "posthog-js";
import { CTARow } from "./ctaRow";

import {
  FAQ,
  Testimonials,
  BentoMobile,
  AllInOne,
  Hero,
  MissionControl,
  FeatureBreakdown,
  RecordSpeed
} from "./home";

// posthog.init("phc_XbaVMJxYBOdYd6i6Ti3AOvAXQpLSLE1cQtvWi7hodfC", { api_host: "https://app.posthog.com" });

const EmailCaptureFallback = () => {
  return (
    <>
      <div className="[ email_capture disable-flow ]">
        <input
          className="[ input email_input ss-large ] [ text-16 round ]"
          type="text"
          maxLength={80}
          required={true}
          placeholder={"Enter your email address"}
          //disabled={i.disabled}
        />
        <button
          className="[ button width-50 email_button width-40 ] [ text-16 ]"
          data-variant="gradient"
          data-size="thin"
        >
          Start for Free
        </button>
      </div>
    </>
  );
};

const Landing = () => {

  return (
    <div className="overflow-x-none">
      <Hero />
      <div className="fixed bottom-4 left-4 z-50">
        <a 
          href="https://www.producthunt.com/posts/sellraze?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sellraze" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <img 
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=573718&theme=light" 
            alt="SellRaze - The fastest, all-in-one tool to sell your inventory online. | Product Hunt" 
            style={{ width: '250px', height: '54px' }}
            width="250" 
            height="54" 
          />
        </a>
      </div>
      <AllInOne />
      <MissionControl />
      <RecordSpeed />
      {/* <HowItWorks/> */}
      <BentoMobile isLanding={true} />
      <FeatureBreakdown />
      <FAQ />
      <Testimonials />
      <CTARow />
      <Footer />
      
    </div>
  );
};

export default Landing;

